export default {
    app_name: '합티',
    intro: {
      start_with_kakao: '카카오로 시작하기',
      start_with_naver: '네이버로 시작하기',
      start_with_email: '일반 회원가입하기',
      login: '로그인',
      explore_without: '회원가입 없이 둘러보기'
    },
    login: {
      email_placeholder: '메일주소를 입력하세요',
      password_placeholder: '비밀번호를 입력하세요',
      find_id: '아이디 찾기',
      find_pwd: '비밀번호 찾기',
      sign_up: '회원가입',
      change_your_phone: '휴대전화를 변경하셨나요?',
      change_phone: '변경 요청하기',
      confirm: '확인'
    },
    find: {
      find_id: '아이디 찾기',
      find_pwd: '비밀번호 찾기',
      phone: '휴대전화',
      find: '찾기',
      success: '완료되었습니다.'
    },
    signup: {
      member_info: '회원정보입력',
      id: '아이디',
      email_placeholder: '이메일 주소를 입력해주세요',
      duplicate_email: '사용중인 이메일 입니다.',
      duplicate_phone: '사용중인 휴대전화번호 입니다.',
      password: '비밀번호',
      current_password: 'Current Password Confirm',
      password_placeholder: '숫자와 영문 대소문자를 혼합해서 입력하세요',
      confirm_placeholder: '틀리지 않도록 다시한번 입력해 주세요',
      mobile_phone_verify: '휴대전화 본인인증',
      home_address: '자택 주소',
      select_home_address: '자택 주소를 선택하세요',
      office_address: '직장 주소',
      select_office_address: '직장 주소를 선택하세요',
      enter_address_correctly: '주소를 정확히 입력할수록 가까운 시설을 추천해드립니다',
      consent_marketing: '마케팅 수신 동의',
      email: '이메일',
      sms: 'SMS',
      push: '푸시알림',
      agree_terms: '약관동의',
      terms_of_use: '이용약관',
      privacy_policy: '개인정보처리방침',
      invitation_code: '초대코드',
      invitation_code_placeholder: '초대코드를 입력해주세요',
      confirm: '확인',
      prev: '이전',
      next: '다음',
      select_gender_age: '성별과 연령대를\n선택해주세요',
      recommend_gender_age: '선택하신 성별과 연령대로 제휴시설이 추천되요.',
      age_woman: '{0}대 여성',
      age_man: '{0}대 남성',
      select_category: '관심있는 카데고리를\n선택해 주세요',
      multiple_possible: '복수선택가능',
      select_area: '관심지역을\n선택해주세요',
      photo_guide: '제휴시설 방문시 본인인증때 필요합니다.\n(본인이 아니면 이용할수 없습니다.)',
      select_photo: '사진선택',
      must_select_photo: '회원사진은 본인이 식별가능한 사진을 선택해야합니다.',
      register_complete: '가입완료',
    },
    gnb: {
      home: '홈',
      near_by: '주변 시설',
      t_gift: 'T선물',
      my_page: '마이페이지'
    },
    home: {
      register_membership: '멤버십 가입 하기',
      check_facilities_around: '내 주변 시설 확인하기',
      guide: '멤버십 프로그램 선택.\n매달 제휴 할인과 혜택으로 더 많은\n혜택을 누려보세요',
      city: '시',
      category: '카테고리',
      more: '더보기',
      notice_event: '공지/이벤트',
      make_reservation: '예약하기',
      used_items: '이용종목',
      user_guide: '이용안내',
      facility_info: '시설정보',
      review: '리뷰',
      reservation: '예약',
      day_list: ['일', '월', '화', '수', '목', '금', '토'],
      select_date: '날짜를 선택하세요',
      not_select_prev_date: '오늘 이후의 날짜를 선택하세요.',
      selected_date: '선택날짜',
      hours_use: '이용시간',
      start_time: '시작시간',
      end_time: '종료시간',
      reservation_confirm: '예약 확인',
      reservation_confirmed: '예약이 확인 되었습니다',
      select_reserve_date: '예약날짜를 선택하세요',
      select_reserve_time: '예약시간을 선택하세요',
      select_reserve_time_correct: '시간을 정확히 선택하세요',
      administrator: '관리자',
      select_service_you_with: '해당 시설에서 제공하는 서비스 중\n이용하시려는 서비스를 선택해 주세요',
      add_required: '{0}원 추가 결제 필요',
      no_required: '추가 결제 불필요',
      login_first: '로그인해주세요'
    },
    t_gift: {
      giftable_ticket: '선물 가능 이용권',
      gift_count: '{0}T',
      guide: '1.카톡 친구에게 1일 1회 1개의 티켓을 선물할 수 있습니다.\n2.선물한 즉시 티켓 개수는 차감되며 취소되지 않습니다.\n3.선물을 보낸 당일은 회원이 티켓을 사용할 수 없습니다.\n4.티켓을 선물받은 사용자도 당일에 사용하지 않으면 티켓이 소멸됩니다.\n5.티켓을 선물받으려면 앱 설치가 필수입니다.\n6.추가 사용료 결제가 필요한 상품은 카드 등록 후 사용 가능합니다.\n7.등급이 맞지 않는 제휴시설은 이용할 수 없습니다.',
      create_link: '1티 선물하기',
      gift_policy: '선물 정책',
      gift_usage_history: '선물 이용 내역',
      id: 'ID',
      facility_used: '이용 시설',
      date_of_use: '이용일시',
      enter_user_name: '이름을 입력하세요',
      gift_confirm: '선물하시겠습니까?',
      gift_success: '선물하였습니다.'
    },
    my_page: {
      change_account: '계정 정보 변경',
      profile_settings: '프로필 설정',
      membership_register_required: '멤버쉽 가입이 필요합니다',
      withdraw: '회원 탈퇴 하기',
      withdraw_success: '탈퇴되었습니다.',
      account_info: '계정 정보',
      account_settings: '계정 설정',
      password_change: '변경할 비밀번호',
      password_change_placeholder: '변경할 비밀번호를 입력하세요',
      confirm_password_change: '변경할 비밀번호 확인',
      confirm_password_change_placeholder: '변경할 비밀번호를 다시 한번 입력해주세요',
      agree_receive: '수신동의',
      current_date: '(현재날짜)',
      membership_level: '회원등급 설정',
      membership_level_guide:
        '하루에 1회 사용 가능한 14회 티켓을 발급 받으실수 있어요.\n' +
        '원하시는 제휴시설의 등급을 확인하시고, 해당 등급으로 설정해주세요.\n' +
        '제휴시설 등급보다 하위등급일 경우 사용제한이 됩니다.',
      facility_available: '이용 가능 시설 {0}개',
      ticket_issued: '{0}회 티켓 발급/1일 1회 사용 가능',
      select: '선택',
      membership_payment: '멤버십 결제',
      start_date_use: '이용시작일자는 {0} 입니다',
      credit_card_payment: '매월 결제일에 자동결제가 이루어집니다',
      use_it_early: '조기 이용을 원하실 경우 이용 시작 일자를 선택해주세요 ',
      select_start_date: '이용시작일자 선택',
      prepaid_early:
        '선결제 된 조기 사용분은 해당 월이 지나면 소멸됩니다.\n' +
        '선결제 조기 사용 시 가입 취소를 할 수 없습니다.',
      membership_fee: '가입비',
      only_charge_once: '멤버십회원 가입시 최초 1회만 청구됩니다.',
      have_promo_code: '프로모션 코드가 있으신가요?',
      discount: '할인',
      enter_code: '코드를 입력하세요',
      confirm_coupon: '쿠폰확인',
      register_corporate: '기업회원으로 등록하시겠습니까?',
      company_placeholder: '기업명을 입력하세요',
      upload: '업로드',
      agree_regular_payment: '정기결제 동의',
      every_month: '매월 25일',
      first_payment: '첫 결제',
      next_payment: '이후 결제',
      agree_membership_fees: '매월 1회 회비출금 정기결제에 동의합니다.',
      i_agree: '동의합니다.',
      start_current_month: '당월 시작하기',
      make_payment: '결제하기',
      payment_success: '결제완료되었습니다.',
      go_to_login: '로그인 하러 가기',
      cancel: '취소',
      web_withdraw: 'PC를 통해 멤버십을 해지해주셔야 합니다.',
      withdrawal_application: '탈퇴 신청',
      today: '{0} (오늘)',
      withdraw_membership: ' 기준 회원을 탈퇴합니다',
      enter_content: '내용 입력',
      like_withdraw: '탈퇴하시겠습니까?',
      will_withdraw: '탈퇴합니다',
      account_modify: '변경되었습니다.'
    },
    side_menu: {
      log_out: '로그아웃',
      log_in: '로그인',
      log_out_confirm: '로그아웃하시겠습니까?',
      ticket_usage: '이용권 사용 / 구매내역',
      faq: '자주묻는 질문',
      faq_type: [
        { type: '', title: '전체' },
        { type: 'S', title: '가입' },
        { type: 'P', title: '제휴' },
        { type: 'M', title: '멤버십' },
      ],
      notice_event: '공지사항/이벤트',
      partnership_inquiry: '제휴문의',
      all: '전체',
      title: '제목',
      content: '내용',
      search: '검색',
      total: '총 {0}건',
      no: '번호',
      notice: '공지사항',
      partnership_guide: '1. 합티는 제휴시설의 만족과 매출을 최우선으로 생각합니다.\n2. 합티를 활용한 지역 커뮤니티에 노출!\n3. 합티를 활용한 신규고객 유치!\n4. 합티를 활용한 SNS홍보!',
      inquiry_about: '제휴시설 문의',
      select_region: '지역 선택',
      facility_type: '시설 종목',
      contact_person: '담당자 성명',
      contact_person_placeholder: '담당자 성명과 직함을 입력해주세요',
      enter_phone_number: '전화번호 입력',
      enter_phone_number_placeholder: '빠른 연락이 가능한 연락처를 입력해주세요',
      memo: '메모',
      apply: '상담 신청',
      input_all: '모든 정보를 입력해주세요',
      inquiry_success: '신청완료되었습니다.',
      ticket_usage_history: '이용권 사용 / 구매내역',
      remain_ticket: '잔여 이용권',
      remain_info: '{0}월',
      facility_used: '이용 시설',
      date_use: '이용 날짜',
      write_review: '리뷰 작성',
      write: '작성',
      review_placeholder: '리뷰내용을 써주세요',
      edit_write: '수정/작성',
      review_saved: '저장되었습니다.'
    },
    nfc: {
      select_service: '해당 시설에서 제공하는 서비스 중\n이용하시려는 서비스를 선택해 주세요',
      classes: '{0} 수업',
      add_required: '{0}원 추가 결제 필요',
      select_option: '선택 옵션',
      payment_method: '결제 방식',
      additional_payment: '추가 결제 : {0}원',
      visit_confirmed: '방문이 확인 되었습니다'
    }
  }
  