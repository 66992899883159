import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import { inject } from "mobx-react";
import dayjs from "dayjs";
import { FORMAT, PAY_TYPE } from "../../../config/const";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CardInfoPopup from '../../popup/card-popup';

const MembershipPayment = ({ id, rootStore }) => {

  const context = useContext(PageSettings);
  const history = useHistory();

  const me = rootStore.getMe;
  const levelInfo = context.getSettingList('level').find(it => it.id == id);

  const [startDate, setStartDate] = useState(new Date());
  const [fee, setFee] = useState(levelInfo.cost);
  const [discount, setDiscount] = useState(0);
  const [code, setCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [payType, setPayType] = useState('');
  const [agree, setAgree] = useState(false);
  const [startMonth, setStartMonth] = useState(false);

  const imageRef = useRef(null);
  const [companyFile, setCompanyFile] = useState(null);

  // 카드관련
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const messageListener = document.addEventListener('message', (nativeEvent) => {
      const data = JSON.parse(nativeEvent?.data);
      if (data.type == 'payResult') {
        if (data.result == 'success') {
          doPayment(data.impUid, data.merchantUid, data.customerUid)
        } else {
          context.showAlert(data.errMsg);
        }
      }
    });

    return messageListener;
  }, []);

  useEffect(() => {
    setTotalPrice(fee - discount);
  }, [fee, discount]);

  const uploadImage = () => {
    if (companyFile == null) {
      return '';
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', companyFile, companyFile.name);

      context.post(
        'upload/image/member',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onPayment = async () => {
    if (payType == '') {
      context.showAlert('결제방식을 선택하세요.');
      return
    }
    if (!agree || !startMonth) {
      context.showAlert('동의해주세요.');
      return;
    }

    if (payType == 'kakaopay') {
      if (context.getOSType() == 'web') {

      } else {
        // 앱인 경우 앱단에서 결제처리
        let obj = {
          func: 'onPayment',
          params: {
            pg: payType,
            amount: totalPrice,
            userName: me?.name,
            userEmail: me?.email,
            userPhone: me?.phone || '01012345678',
            payName: levelInfo.name + ' 멤버쉽 결제'
          }
        };

        let message = JSON.stringify(obj);
        window.ReactNativeWebView.postMessage(message);
      }
    } else {
      // 카드 정기결제인 경우 카드정보 입력 후 REST API로 결제진행
      setShowPopup(true);
    }
  };

  const doPayment = async (impUid, merchantUid, customerUid) => {
    const companyUploadFile = ''; // await uploadImage();
    context.post(
      'member/payment',
      {
        start_date: dayjs(startDate).format(FORMAT.DATE),
        level: levelInfo.id,
        type: PAY_TYPE.MEMBERSHIP,
        code,
        price: totalPrice,
        discount,
        company_name: companyName,
        company_file: companyUploadFile,
        imp_uid: impUid,
        merchant_uid: merchantUid,
        customer_uid: customerUid
      },
      () => {
        context.getMyInfo();
        context.showAlert(strings.my_page.payment_success, () => history.push('/main'));
      }
    );
    context.toggleLoadingBar(false);
  }

  const onConfirmCoupon = () => {
    if (code == '') {
      context.showAlert(strings.my_page.enter_code);
      return;
    }

    // 쿠폰확인 코드
  };

  const photoCallback = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setCompanyFile(file);
    };
    reader.readAsDataURL(file);
  }

  const getBillingKey = (customer_uid, merchant_uid, card_number, expiry, birth, pwd) => {
    context.toggleLoadingBar(true);
    context.post(
      'member/getBillingKey',
      {
        card_number: card_number,
        expiry: expiry,
        birth: birth,
        pwd: pwd,
        customer_uid: customer_uid
      },
      response => {
        paymentWithBillingKey(customer_uid, merchant_uid, totalPrice);
      },
      failure => {
        console.log('billing key failure', failure);
        context.toggleLoadingBar(false);
      }
    );
  }

  const paymentWithBillingKey = (customerUid, merchantUid, amount) => {
    context.post(
      'member/cardPayment',
      {
        customer_uid: customerUid,
        merchant_uid: merchantUid,
        amount: amount,
        name: levelInfo.name + ' 멤버쉽 결제',
        buyer_name: me?.name,
        buyer_tel: me?.phone,
        buyer_email: me?.email
      },
      response => {
        doPayment(response.imp_uid, merchantUid, customerUid);
      },
      failure => {
        context.toggleLoadingBar(false);
      }
    );
  }

  return (
    <Container className='membership-payment'>
      <TopBar center={true} title={strings.my_page.membership_payment} />
      <div className='content'>
        <div className='header' style={{justifyContent: 'center',textAlign: 'center', fontSize: 14, lineHeight: '18px'}}>
          <p>
            <span style={{fontSize: 14}}>{me.name}</span> 회원님의 멤버십 이용시작 일자는<br />
            {dayjs(startDate).format(FORMAT.DATE) + ' 입니다.'}<br />
            {strings.my_page.credit_card_payment}
          </p>
        </div>
        <div className='box'>
          {/* <div className='membership-fee'>
            <div className='top'>
              <label className='caption'>{strings.my_page.membership_fee}</label>
              <label className='price'>{fee.currencyFormat()}원</label>
            </div>
            <label className='description'>{strings.my_page.only_charge_once}</label>
          </div> */}
          <div className='group'>
            <div className='top' style={{fontSize: 12}}>
              <label className='caption'>{strings.my_page.have_promo_code}</label>
              <label className='price'></label>
            </div>
            <div className='bottom'>
              <input value={code} onChange={e => setCode(e.target.value)} placeholder={strings.my_page.enter_code} style={{ width: 100 }} />
              <button className='btn-coupon' onClick={onConfirmCoupon}>{strings.my_page.confirm_coupon}</button>
            </div>
            <div className='top' style={{marginTop: 10, fontSize: 12}}>
              <label className='caption'></label>
              <label className='price'>{strings.my_page.discount} <span>{discount.currencyFormat()}원</span></label>
            </div>
          </div>
          {/* <div className='group'>
            <div className='top'>
              <label className='caption'>{strings.my_page.register_corporate}</label>
            </div>
            <div className='bottom'>
              <input value={companyName} onChange={e => setCompanyName(e.target.value)}
                placeholder={strings.my_page.company_placeholder} style={{ width: 100 }} />
              <button className='btn-coupon' onClick={() => imageRef.current?.click()}>{strings.my_page.upload}</button>
              <input type='file' ref={imageRef} style={{ display: 'none' }} accept={'image/png,image/jpg,image/jpeg'}
                onChange={photoCallback} />
            </div>
          </div> */}
          <div className='sum' style={{fontSize: 16, justifyContent: 'center'}}>
            <label>{'멤버십 '}</label>
            <label className='total-price'><span>{totalPrice.currencyFormat()}</span>원</label>
          </div>
          <div className='sum' style={{fontSize: 16, justifyContent: 'center'}}>
            <label>{'(+/- 할인) '}</label>
            <label className='total-price'><span>{discount.currencyFormat()}</span>원</label>
          </div>
          <div className='sum' style={{fontSize: 16, justifyContent: 'center'}}>
            <label>{'결제금액 '}</label>
            <label className='total-price'><span>{(totalPrice - discount).currencyFormat()}</span>원</label>
          </div>
          <div className='payment'>
            <div className='row'>
              <label className='caption'>{strings.my_page.first_payment}</label>
              <div className='right'>
                <label className='date'>{dayjs(startDate).format(FORMAT.DATE)}</label>
              </div>
            </div>
            <div className='row'>
              <label className='caption'>{strings.my_page.next_payment}</label>
              <div className='right'>
                <label className='date'>{dayjs(startDate).add(1, 'month').format(FORMAT.DATE)}</label>
              </div>
            </div>
          </div>
          <div className='payment'>
          <div className='row'>
              <label className='caption'>{'결제방식'}</label>
              <div className='right' style={{ display: 'flex', alignItems: 'center' }}>
                <button className='btn-check' onClick={() => setPayType('card')} style={{ marginRight: 15 }}>
                  <img srcSet={payType == 'card' ?
                    '../../assets/images/icon_radio_checked.png 3x' :
                    '../../assets/images/icon_radio_unchecked.png 3x'
                  } />
                  {'카드결제'}
                </button>
                {/* <button className='btn-check' onClick={() => setPayType('trans')} style={{ marginRight: 15 }}>
                  <img srcSet={payType == 'trans' ?
                    '../../assets/images/icon_radio_checked.png 3x' :
                    '../../assets/images/icon_radio_unchecked.png 3x'
                  } />
                  {'계좌이체'}
                </button> */}
                {/* <button className='btn-check' onClick={() => setPayType('kakaopay')}>
                  <img srcSet={payType == 'kakaopay' ?
                    '../../assets/images/icon_radio_checked.png 3x' :
                    '../../assets/images/icon_radio_unchecked.png 3x'
                  } />
                  {'카카오페이'}
                </button> */}
              </div>
            </div>
          </div>
          <div className='agree'>
            <div className='header'>
              <label className='title'>{strings.my_page.agree_membership_fees}</label>
            </div>
            <div className='body'>
              <button className='btn-check' onClick={() => setAgree(prev => !prev)}>
                <img srcSet={agree ?
                  '../../assets/images/icon_checkbox_checked.png 3x' :
                  '../../assets/images/icon_checkbox_unchecked.png 3x'
                } />
                {strings.my_page.i_agree}
              </button>
            </div>
          </div>
          <div className='agree' style={{ marginTop: 18 }}>
            <div className='header'>
              <label className='title'>{strings.my_page.agree_membership_fees}</label>
            </div>
            <div className='body'>
              <button className='btn-check' onClick={() => setStartMonth(prev => !prev)}>
                <img srcSet={startMonth ?
                  '../../assets/images/icon_checkbox_checked.png 3x' :
                  '../../assets/images/icon_checkbox_unchecked.png 3x'
                } />
                {strings.my_page.start_current_month}
              </button>
            </div>
          </div>
        </div>
        <button className='btn-make' onClick={onPayment}>{strings.my_page.make_payment}</button>
      </div>
      <CardInfoPopup
        active={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
        onConfirm={(cardNum, expiry, birth, pwd) => {
          setShowPopup(false);
          const customer_uid = `cid_${new Date().getTime()}`;
          const merchant_uid = `mid_${new Date().getTime()}`;
          getBillingKey(customer_uid, merchant_uid, cardNum, expiry, birth, pwd);
        }} />
    </Container>
  );
}

export default inject('rootStore')(MembershipPayment);
