import React, { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import { withRouter } from "react-router-dom";
import TopBar from "../../../components/control/top-bar";
import strings from "../../../lang/strings";
import dayjs from "dayjs";
import { FORMAT, PAY_TYPE } from "../../../config/const";

const Reservation = ({ id, history, rootStore }) => {

  const context = useContext(PageSettings);

  const [month, setMonth] = useState(dayjs().format(FORMAT.MONTH));
  const [dateList, setDateList] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [facilityInfo, setFacilityInfo] = useState(null);

  useEffect(() => {
    if (context.getOSType() == 'ios') {
      window.addEventListener('message', handleMessage);
    } else {
      document.addEventListener('message', handleMessage);
    }

    getInfo();

    return () => {
      if (context.getOSType() == 'ios') {
        window.removeEventListener('message', handleMessage);
      } else {
        document.removeEventListener('message', handleMessage);
      }
    };
  }, []);

  useEffect(() => {
    getDateList();
  }, [month]);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'payResult') {
        if (data.result == 'success') {
          savePayment(data.impUid, data.merchantUid)
        } else {
          context.showAlert(data.errMsg);
        }
      }
    }
  }

  const getInfo = () => {
    // 시설상세정보얻기
    context.get(
      `facility/detail/${id}`,
      {},
      response => {
        setFacilityInfo(response);
      }
    );
  }

  const savePayment = (imp_uid, merchant_uid)  => {
    context.post(
      'member/payment',
      {
        start_date: '',
        level: 0,
        type: PAY_TYPE.RESERVATION,
        facility: id,
        code: '',
        price: facilityInfo.reservation_discount,
        discount: (facilityInfo.reservation_price - facilityInfo.reservation_discount),
        company_name: '',
        company_file: '',
        imp_uid: imp_uid,
        merchant_uid: merchant_uid,
        customer_uid: ''
      },
      () => {
        saveReservation();
      }
    );
  }

  const getDateList = () => {
    let firstDate = dayjs(dayjs(month).startOf('month')).startOf('week');
    const endDate = dayjs(dayjs(month).endOf('month')).endOf('week');

    let list = [];
    while (firstDate.isSame(endDate) || firstDate.isBefore(endDate)) {
      list.push({
        date: firstDate.format(FORMAT.DATE),
        active: firstDate.format(FORMAT.MONTH) == month
      });
      firstDate = firstDate.add(1, 'day');
    }
    setDateList(list);
  }

  const onChangeMonth = (amount) => {
    setMonth(prev => dayjs(prev).add(amount, 'month').format(FORMAT.MONTH));
  }

  const onSelectDate = date => {
    if (!dayjs(date).isAfter(dayjs().startOf('date'))) {
      context.showAlert(strings.home.not_select_prev_date);
      return;
    }

    setSelectedDate(date);
  }

  const onReserve = () => {
    if (selectedDate == '') {
      context.showAlert(strings.home.select_reserve_date);
      return;
    }
    if (startTime == '00:00' && endTime == '00:00') {
      context.showAlert(strings.home.select_reserve_time);
      return;
    }
    if (dayjs(`${selectedDate} ${startTime}:00`).isBefore(dayjs())) {
      context.showAlert(strings.home.select_reserve_time_correct);
      return;
    }

    context.showConfirm(
      '예약하시겠습니까?',
      '예약을 하시는 경우 1회 티켓이 차감됩니다.',
      '확인',
      '취소',
      response => {
        if (response == true) {
          // 예약진행
          if (facilityInfo?.reservation_price != 0) {
            // 예약금액이 있는 경우 결제 진행
            let obj = {
              func: 'onPayment',
              params: {
                pg: 'card',
                amount: facilityInfo.reservation_discount,
                userName: rootStore.getMe?.name,
                userEmail: rootStore.getMe?.email,
                userPhone: rootStore.getMe?.phone || '01012345678',
                payName: facilityInfo.name + ' 예약 결제'
              }
            };

            let message = JSON.stringify(obj);
            window.ReactNativeWebView.postMessage(message);
          } else {
            saveReservation();
          }
        }
      }
    )
  }

  const saveReservation = () => {
    context.post(
      `facility/reserve/${id}`,
      {
        date: selectedDate,
        start_time: startTime,
        end_time: endTime
      },
      () => {
        context.showConfirm(
          strings.home.reservation_confirm,
          strings.home.reservation_confirmed,
          strings.signup.confirm,
          ''
        );
      }
    );
  }

  return (
    <Container className='reservation'>
      <TopBar center={true} title={strings.home.reservation} />

      <div className='content'>
        <label className='label'>{strings.home.select_date}</label>
        <div className='month'>
          <button onClick={() => onChangeMonth(-1)}>
            <img srcSet={'../assets/images/icon_arrow_left.png 3x'} alt='prev' />
          </button>
          <label>{month}</label>
          <button onClick={() => onChangeMonth(1)}>
            <img srcSet={'../assets/images/icon_arrow_right.png 3x'} alt='prev' />
          </button>
        </div>
        <div className='calendar'>
          <div className='header'>
            {strings.home.day_list.map((it, idx) => <label key={idx}>{it}</label>)}
          </div>
          <div className='body'>
            {
              dateList.map((it, idx) => (
                <button
                  key={idx}
                  className={(!it.active ? 'inactive' : '') + (it.date == selectedDate ? ' selected' : '')}
                  onClick={() => onSelectDate(it.date)}>
                  {dayjs(it.date).format('D')}
                </button>
              ))
            }
          </div>
        </div>

        <div className='row'>
          <label className='caption'>{strings.home.selected_date}</label>
          <div className='input-wrapper date'>
            {selectedDate}&nbsp;
            {
              selectedDate != '' && strings.home.day_list[dayjs(selectedDate).format('d')]
            }
          </div>
        </div>
        <div className='row'>
          <label className='caption'>{strings.home.hours_use}</label>
          <div className='input-wrapper time'>
            {
              facilityInfo?.reservation_time.split(',').map((item, idx) => (
                <div key={idx} className={'time-label' + (startTime == item ? ' selected' : '')} onClick={() => {
                  setStartTime(item);
                }}>{item}</div>
              ))
            }
            {/* <div>
              <label className='title'>{strings.home.start_time}</label>
              <label className='time-label'>{startTime}</label>
              <input type={'time'} value={startTime} onChange={e => setStartTime(e.target.value)} />
            </div>
            <div>
              <label className='title'>{strings.home.end_time}</label>
              <label className='time-label'>{endTime}</label>
              <input type={'time'} value={endTime} onChange={e => setEndTime(e.target.value)} />
            </div> */}
          </div>
        </div>

        <button className='btn-reserve' onClick={onReserve}>{strings.home.make_reservation}</button>
      </div>
    </Container>
  );
}

export default withRouter(Reservation);
