import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../../config/page-settings';
import Container from "../../components/control/container";
import strings from "../../lang/strings";

import '../../scss/pages/intro.scss';
import { KAKAO_APP_KEY, LOGIN_TYPE, NAVER_CALLBACK_URL, NAVER_CLIENT_ID } from "../../config/const";
import { inject } from "mobx-react";
import { storage } from "../../mobx/store";
import KakaoLogin from 'react-kakao-login';


const Intro = ({ history, rootStore }) => {
  const context = useContext(PageSettings);

  const naverRef = useRef()
  const { naver } = window;

  useEffect(() => {
    let messageListener;
    if (context.getOSType() == 'ios') {
      messageListener = window.addEventListener('message', handleMessage);
    } else {
      messageListener = document.addEventListener('message', handleMessage);
    }

    if (window.ReactNativeWebView) {
      getDeviceId();
    } else {
      initializeNaverLogin();
      checkAutoLogin('');
    }

    return messageListener;
  }, []);

  const handleMessage = (event) => {
    if (typeof event?.data != "object" && event?.data.isJSON()) {
      const data = JSON.parse(event?.data);
      if (data.type == 'snsLogin') {
        const snsType = data.sns_type == 'kakao' ? LOGIN_TYPE.KAKAO : data.sns_type == 'naver' ? LOGIN_TYPE.NAVER : LOGIN_TYPE.EMAIL;
        const snsID = data.sns_id;
        const snsEmail = data.sns_email;
        tryLogin(snsType, snsEmail, snsID);
      } else if (data.type == 'setDeviceId') {
        context.handleSetDeviceId(data.deviceId);
        checkAutoLogin(data.deviceId);
      }
    }
  }

  const getDeviceId = () => {
    let obj = {
      func: 'getDeviceId',
      params: {}
    };

    let message = JSON.stringify(obj);
    window.ReactNativeWebView.postMessage(message);
  }

  const checkAutoLogin = (deviceId) => {
    const info = storage.getAutoLogin();
    if (info == null) {
      return;
    }

    context.post(
      'auth/login',
      {
        login_type: info.type,
        device_id: deviceId,
        email: info.id,
        pwd: info.pwd,
        os: context.getOSType()
      },
      response => {
        rootStore.signIn(response.token);
        history.push('/main');
      },
      () => {
      }
    );
  }

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_ID,
      callbackUrl: NAVER_CALLBACK_URL,
      isPopup: true, // 팝업으로 띄울것인지 설정
      loginButton: { color: 'white', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
      callbackHandle: false,
    });
    naverLogin.init();
    naverLogin.logout();
    naverLogin.getLoginStatus(function (status) {
      if (status) {
        const id = naverLogin.user.getId();
        const email = naverLogin.user.getEmail();

        if (email === null || email === undefined) {
          alert("이메일이 필요합니다. 정보제공을 동의해주세요.");
          naverLogin.reprompt();
          return;
        } else {
          tryLogin(LOGIN_TYPE.NAVER, email, id);
        }
      }
    });
  };

  const onStartKakao = () => {
    let obj = {
      func: 'onSNSLogin',
      params: {
        type: 'kakao'
      }
    };

    let message = JSON.stringify(obj);
    window.ReactNativeWebView.postMessage(message);
  }

  const onStartNaver = (e) => {
    if (window.ReactNativeWebView) {
      let obj = {
        func: 'onSNSLogin',
        params: {
          type: 'naver'
        }
      };

      let message = JSON.stringify(obj);
      window.ReactNativeWebView.postMessage(message);
    } else {
      e.preventDefault();

      naverRef.current.children[0].click()
    }
  }

  const onStartEmail = () => {
    storage.saveSignupUser({
      login_type: LOGIN_TYPE.EMAIL,
      sns_id: ''
    });
    history.push('/signup');
  }

  const onLogin = () => {
    history.push('/login');
  }

  const onExplore = () => {
    // 로그인 거치지 않고 둘러보기로 메인화면으로 진입
    history.push('/main');
  }

  const tryLogin = (login_type, email, sns_id) => {
    context.post(
      'auth/login',
      {
        login_type,
        email: sns_id,
        device_id: context.getDeviceId(),
        os: context.getOSType()
      },
      response => {
        rootStore.signIn(response.token);
        storage.setAutoLogin(login_type, sns_id);
        history.push('/main');
      },
      () => {
        storage.saveSignupUser({
          login_type,
          email,
          sns_id
        });
        history.push('/signup');
      }
    )
  }

  return (
    <Container className='intro'>
      <div className='logo-img'>
        <img src='assets/images/logo1.png' />
      </div>

      <div className='logo-desc'>
        <div className='samliphopang'>{'합리적인 티켓! 합티!'}</div>
        <div>{'한번의 가입으로 다양한 제휴시설을 이용하고,'}</div>
        <div>{'최대 70%회비절감 효과를 체험해보세요'}</div>
      </div>

      {/* {
        context.getOSType() != 'ios' && */}
        <KakaoLogin
          token={KAKAO_APP_KEY}
          onSuccess={(res) => {
            const snsType = LOGIN_TYPE.KAKAO;
            const snsID = res.profile.id;
            const snsEmail = res.profile.kakao_account.email;
            tryLogin(snsType, snsEmail, snsID);
          }}
          onFail={(err) => {
            console.error(err);
          }}
          onLogout={console.info}
          render={({ onClick }) => {
            return (
              <button className='btn btn-sns btn-kakao' onClick={(e) => {
                e.preventDefault();
                if (window.ReactNativeWebView) {
                  onStartKakao()
                } else {
                  onClick();
                }
              }}>
                <img src='assets/images/icon_kakao.png' />
                {strings.intro.start_with_kakao}
              </button>
            );
          }}
        />
      {/* } */}
      {/* <button className='btn btn-sns btn-kakao' onClick={onStartKakao}>
        <img src='assets/images/icon_kakao.png' />
        {strings.intro.start_with_kakao}
      </button> */}
      {/* {
        context.getOSType() != 'ios' && */}
        <button className='btn btn-sns btn-naver' onClick={onStartNaver}>
          <img src='assets/images/icon_naver.png' />
          {strings.intro.start_with_naver}
          <div id='naverIdLogin' className='naver' ref={naverRef} style={{ display: 'none' }} />
        </button>
      {/* } */}
      <button className='btn btn-sns btn-email' onClick={onStartEmail}>{strings.intro.start_with_email}</button>
      <hr />
      <button className='btn btn-outline btn-login' onClick={onLogin}>{strings.intro.login}</button>
      <button className='btn btn-explore' onClick={onExplore}>{strings.intro.explore_without}</button>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Intro));
