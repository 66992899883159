import React, { useContext, useState } from "react";
import { PageSettings } from "../../config/page-settings";

const CardInfoPopup = ({ active, onClose, onConfirm }) => {
  const context = useContext(PageSettings);

  const [cardNumber1, setCardNumber1] = useState("");
  const [cardNumber2, setCardNumber2] = useState("");
  const [cardNumber3, setCardNumber3] = useState("");
  const [cardNumber4, setCardNumber4] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [birth, setBirth] = useState("");
  const [pwd, setPwd] = useState("");

  const onPayment = () => {
    if (cardNumber1.length < 4 || cardNumber2.length < 4 || cardNumber3.length < 4 || cardNumber4.length < 4) {
      context.showAlert('카드번호를 입력하세요.');
      return;
    }

    if (expiryYear.length < 4 || expiryMonth.length < 2) {
      context.showAlert('유효기간을 입력하세요.');
      return;
    }

    if (birth.length < 6) {
      context.showAlert('생년월일을 입력하세요.');
      return;
    }
    
    if (pwd.length < 2) {
      context.showAlert('비밀번호를 입력하세요.');
      return;
    }

    const cardNumber = `${cardNumber1}-${cardNumber2}-${cardNumber3}-${cardNumber4}`;
    const expiry = `${expiryYear}-${expiryMonth}`;

    onConfirm(cardNumber, expiry, birth, pwd);
  }

  return (
    <div
      className={"card-info-popup" + (active ? " active" : "")}
      onClick={onClose}
    >
      <div className="content" onClick={(e) => e.stopPropagation()}>
        <div className="title">카드정보 입력</div>
        <div className="divider" />
        <div className="info-label" style={{marginTop: 'auto'}}>카드번호</div>
        <div className="card-number">
          <input
            type="number"
            value={cardNumber1}
            maxLength={4}
            placeholder={"0000"}
            onChange={(e) => setCardNumber1(e.target.value)}
          />
          <span>-</span>
          <input
            type="number"
            value={cardNumber2}
            maxLength={4}
            placeholder={"0000"}
            onChange={(e) => setCardNumber2(e.target.value)}
          />
          <span>-</span>
          <input
            type="number"
            value={cardNumber3}
            maxLength={4}
            placeholder={"0000"}
            onChange={(e) => setCardNumber3(e.target.value)}
          />
          <span>-</span>
          <input
            type="number"
            value={cardNumber4}
            maxLength={4}
            placeholder={"0000"}
            onChange={(e) => setCardNumber4(e.target.value)}
          />
        </div>
        <div className="info-label">유효기간</div>
        <div className="expiry">
          <input
            type="number"
            value={expiryYear}
            maxLength={4}
            placeholder={"2025"}
            onChange={(e) => setExpiryYear(e.target.value)}
          />
          <span style={{marginLeft: 10, marginRight: 10}}>-</span>
          <input
            type="number"
            value={expiryMonth}
            maxLength={2}
            placeholder={"12"}
            onChange={(e) => setExpiryMonth(e.target.value)}
          />
        </div>
        <div className="info-label">생년월일 6자리</div>
        <div className="expiry">
          <input
            type="number"
            value={birth}
            maxLength={6}
            placeholder={"900101"}
            onChange={(e) => setBirth(e.target.value)}
          />
        </div>
        <div className="info-label">카드 비밀번호 앞 2자리</div>
        <div className="expiry">
          <input
            type="number"
            value={pwd}
            maxLength={2}
            placeholder={"00"}
            onChange={(e) => setPwd(e.target.value)}
          />
        </div>
        <div className="buttons">
          <div className="payment-button" onClick={onPayment}>결제하기</div>
          <div className="cancel-button" onClick={onClose}>취소</div>
        </div>
      </div>
    </div>
  );
};

export default CardInfoPopup;
