import React, { useContext, useEffect, useRef, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import Container from "../../../components/control/container";
import TopBar from "../../../components/control/top-bar";
import { withRouter } from "react-router-dom";
import { rootStore } from '../../../mobx/store';
import strings from '../../../lang/strings';

const Search = ({ id, history }) => {
  const context = useContext(PageSettings);

  const [keyword, setKeyword] = useState('');
  const [facilityList, setFacilityList] = useState([]);

  const loadingEnd = useRef(false);
  const keywordRef = useRef('');
  const offsetRef = useRef(0);

  // 카테고리리스트
  const categoryList = [{
    id: 0,
    name: strings.home.category
  }].concat(context.getSettingList('category'));

  useEffect(() => {
    document.body.onscroll = () => {
      const reachEnd = window.scrollY + window.innerHeight >= document.body.scrollHeight;
      if (reachEnd && offsetRef.current > 0) {
        getFacilityList();
      }
    };

    return () => {
      document.body.onscroll = null;
    }
  }, []);

  useEffect(() => {
    keywordRef.current = keyword;
    offsetRef.current = facilityList.length;
  }, [keyword, facilityList]);

  const getFacilityList = (refresh = false) => {
    if (!refresh && loadingEnd) {
      return;
    }

    // 시설리스트
    context.get(
      'facility/search',
      {
        keyword: keyword,
        latitude: rootStore.getLatitude,
        longitude: rootStore.getLongitude,
        offset: refresh ? 0 : offsetRef.current,
        limit: 20
      },
      response => {
        setFacilityList(prev => refresh ? response.list : prev.concat(response.list));
        loadingEnd.current = !response.loadMore;
      }
    );
  }

  const correctDistance = (distance) => {
    if (distance == '') {
      return '0.0km';
    } else {
      const dist = parseFloat(distance);
      return dist.toFixed(1) + 'km'
    }
  }

  const onSearch = () => {
    if (keyword.length == 0) {
      return;
    }

    getFacilityList(true);
  }

  return (
    <Container className='search'>
      <TopBar center={true} title={'검색'} />
      <div className='content'>
        <div className='header'>
          <div className='input-wrapper'>
            <input type='text' value={keyword} placeholder={'검색어를 입력하세요.'}
              onChange={e => setKeyword(e.target.value)} onKeyUp={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  onSearch();
                }
              }} />
          </div>
          <button className='btn-search' onClick={onSearch}>
            <img srcSet={'./assets/images/icon_search_black.png 3x'} />
          </button>
        </div>
        <div className='body'>
          {
            facilityList.map((it, idx) => {
              const categoryIds = it.category.split(',');
              return <button key={idx} className='item' onClick={() => history.push(`/facility/${it.id}`)}>
                <div className='image'>
                  <img src={it.photo.getImages()} alt='image' />
                </div>
                <div className='content'>
                  <div className='info'>
                    <label className='name'>{it.name}</label>
                    <label className='distance'>{correctDistance(it.distance ?? '')}</label>
                  </div>
                  <div className='info'>
                    <label className='address'>{it.address}</label>
                    <label className='level'>{it.level_name}</label>
                  </div>

                  <div className='rate'>
                    <img src='/assets/images/icon_star_yellow.png' />
                    {Number(it.rate).toFixed(1)}
                    <span>{`(${Number(it.review_count).currencyFormat()})`}</span>
                  </div>

                  <div className='category'>
                    {
                      categoryIds.map((id, _) => (
                        <div key={id}>{categoryList.filter(c => c.id == id)[0]?.name}</div>
                      ))
                    }
                  </div>
                </div>
              </button>
            })
          }
        </div>
      </div>
    </Container>
  );
}

export default withRouter(Search);
